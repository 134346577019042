import React from 'react'
import { Link, graphql } from 'gatsby'
import Masonry from 'react-masonry-component'
import Img from 'gatsby-image'
import { isFirefox, isIOS } from "react-device-detect";
import { canUseWebP } from "react-img-webp";

const WorkPage = ({ data }) => (
    <Masonry className="showcase">
      {data.allDatoCmsWork.edges.map(({ node: work }) => (
        <div key={work.id} className="showcase__item">
          <figure className="card">
            <Link to={`/arbeiten/${work.slug}`} className="card__image">
              {(canUseWebP() || (isFirefox && !isIOS)) ? 
                <Img className={work.invertCoverImage ? 'invert' : ''} backgroundColor="#3b6a6f82" fluid={work.coverImage.webp} />
                :
                <Img className={work.invertCoverImage ? 'invert' : ''} backgroundColor="#3b6a6f82" fluid={work.coverImage.auto} />
              }
            </Link>
            <figcaption className="card__caption">
              <h6 className="card__title">
                <Link to={`/arbeiten/${work.slug}`}>{work.title}</Link>
              </h6>
              <div
                className="card__description"
                dangerouslySetInnerHTML={{
                  __html: work.excerpt,
                }}
              />
            </figcaption>
          </figure>
        </div>
      ))}
    </Masonry>
)

export default WorkPage

export const query = graphql`
  query WorkPageQuery {
    allDatoCmsWork(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          id
          title
          slug
          excerpt
          coverImage {
            auto: fluid(maxWidth: 450, imgixParams: { bg: "white", auto: "compress, format" }) {
              ...GatsbyDatoCmsSizes_noBase64
            }
            webp: fluid(maxWidth: 450, imgixParams: { bg: "white", fm:"webp", auto: "compress" }) {
              ...GatsbyDatoCmsSizes_noBase64
            }
          }
          invertCoverImage
        }
      }
    }
  }
`
